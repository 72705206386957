.create_edit_container {
  padding: 30px;
  display: flex;
  justify-content: center;
  width: 100%;

  .form_container {
    width: 100%;
  }

  @media (max-width: 480px) {
    padding: 16px;
  }

  .create_section {
    width: 50%;
    padding: 32px 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    gap: 16px;

    background-color: white;

    border-radius: 15px;
    padding-bottom: 30px;
    box-shadow: 0 0 2px 0 rgba(56, 56, 56, 0.7);
    -webkit-box-shadow: 0 0 2px 0 rgba(56, 56, 56, 0.7);
    -moz-box-shadow: 0 0 2px 0 rgba(56, 56, 56, 0.7);

    @media (max-width: 1440px) {
      width: 70%;
    }

    @media (max-width: 768px) {
      width: 90%;
    }

    .field_container {
      width: 85%;

      @media (max-width: 480px) {
        width: 100%;
      }

      .files_container {
        display: flex;
        flex-direction: row;
        width: 95%;
        margin-top: 75px;
        justify-content: space-between;

        .file_content {
          margin: 0;
          width: 30%;

          @media (max-width: 480px) {
            width: auto;
          }

          .image_wrapper {
            width: 220px;

            @include ipad {
              width: 100%;
              height: 150px;
            }
          }
        }

        @include phone {
          flex-direction: column;
          gap: 25px;

          .image_wrapper {
            min-width: 80%;
            width: 200px;
            height: auto;
            min-height: 150px;
          }
        }
      }

      .image_wrapper {
        display: flex;
        flex-direction: column;
        height: 150px;
        width: 150px;
        cursor: pointer;
        z-index: 100;
        position: relative;

        .image_label {
          position: absolute;
          z-index: 1000;
          top: -21px;
          left: 50%;
          transform: translateX(-50%);
          margin: 0;
          font-size: 12px;
          white-space: nowrap;
          color: $black;
        }

        .delete_btn {
          display: none;
          position: absolute;
          top: 5px;
          z-index: 100;
          right: 5px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: white;
          margin: 0;
          padding: 0;
          font-size: 0;
          border: none;

          @include maxIpad {
            display: block;
          }
        }

        &:hover {
          .delete_btn {
            display: block;
          }
        }

        .create_edit_image_input {
          display: none;
        }

        .create_edit_image {
          height: 100%;
          width: 100%;
          overflow: hidden;

          border: 1px solid rgb(213, 209, 209);
          background-color: white;
          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          & > svg {
            width: 40px;
            height: 40px;

            color: rgb(224, 219, 219);

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          & > img {
            height: 100%;
            width: 100%;

            -o-object-fit: contain;
            object-fit: contain;
          }

          &.create_edit_image_error {
            border: 1px solid red;
          }

          .video_section {
            width: 100%;
          }
        }

        .link_icon_background {
          background: $black;
        }
      }
    }

    .price_section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .choose_files_container {
      width: 85%;

      @media (max-width: 480px) {
        width: 100%;
      }

      .insert_file {
        font-size: 24px;
        color: #070919;
      }

      .Mui-selected {
        background-color: white;
      }

      .choose_file_header {
        width: 100%;
        border-bottom: 1px solid #070919;

        .choose_type {
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}

.form_button {
  background-color: $primary;
  color: white;
  border: none;
  width: 94px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  padding: 10px 15px;
  border-radius: 7px;

  & > svg {
    margin-left: 5px;
    color: white;
  }
}
