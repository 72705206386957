.user_actions_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  column-gap: 8px;

  .action_button {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  .edit_icon {
    width: 20px !important;
    height: 20px !important;

    &:hover {
      color: $primary;
    }
  }
}

.panding_icon {
  color: orange;
}

.completed_icon {
  color: green;
}

.status_label {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
}

.completed_label {
  background: $complete_status_bg;
  color: $complete_status;
}

.incompleted_label {
  background: $incomplete_status_bg;
  color: $incomplete_status;
}

.blocked_label {
  color: $blocked_status;
  background: $blocked_status_bg;
}

.hover_label {
  transition-duration: 0.5s;
  transition-property: transform;

  &:hover {
    transform: translateY(-2px);
  }
}

.pagination_container {
  display: flex;
}

.custom-select {
  height: 46px;
  padding: 10.5px 0;
}

.custom-label {
  padding-top: 4px !important;
}

.custom-infinity-select {
  height: 46px !important;
}

.custom-infinity-input {
  padding: 9px 65px 9px 0 !important;
}

.custom-infinity-label {
  padding-top: 4px !important;
}