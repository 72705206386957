.table_page_body_container {
  padding: 30px;

  @media (max-width: 480px) {
    padding: 16px;
  }
}

.position_relative {
  position: relative;
}
