// Core

@import "core";

// Fonts

@import url("https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap");

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.full_width {
  width: 100%;
}

.checkbox_container {
  width: 100%;
}

.MuiDataGrid-menuIconButton {
  display: none !important;
}

.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.direction_column {
  flex-direction: column;
}

.MuiPopover-paper {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.MuiPopper-root {
  z-index: 9999 !important;
}

.MuiAutocomplete-popper {
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.MuiAutocomplete-listbox {
  max-height: 200px !important;
}

.flex_center {
  justify-content: space-between !important;
}

.action_button {
  background-color: transparent;


  &:hover {
    background-color: transparent;
  }

  .edit_icon {
    width: 20px !important;
    height: 20px !important;

    &:hover {
      path {
        fill: $primary;
      }
    }
  }
}

.height_fit_content {
  height: fit-content !important;
}

.capitalize {
  text-transform: capitalize;
}

.custom_height {
  height: fit-content !important;
}
