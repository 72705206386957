.login_page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include maxIpad {
    flex-direction: column;
  }

  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
      box-shadow: 0 0 0 1000px white inset;
    }
  }

  .login_page_form {
    width: 50%;
    @include maxIpad {
      width: 100%;
      padding: 30px;
    }
  }

  .login_field_container {
    width: 450px;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02),
      0px 4px 9px 1px rgba(50, 71, 92, 0.04),
      0px 2px 9px 0px rgba(50, 71, 92, 0.06);
    height: fit-content;
    margin: auto;

    @include phone {
      width: 100%;
      padding: 20px 15px;
    }

    .logo_filed {
      width: 100%;
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
    }

    .text_filed {
      margin-bottom: 18px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .welcome_text {
        color: $primary;
        font-size: 20px;
        font-weight: 800;
        margin: 0;
        padding: 0;
        line-height: 32px;
      }

      .signin_text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $light-text-secondary;
        margin: 0;
        padding: 0;
      }
    }

    .login_checkbox {
      color: $light-text-primary;
      border-color: $light-text-primary;

      svg {
        fill: $light-text-disable;
      }
    }
  }

  .login_image_field {
    width: 50%;
    height: 100%;
    max-height: 100vh;

    @include maxIpad {
      width: 100%;
    }
  }

  .login_image {
    width: 100%;
    object-fit: cover;
    max-height: 100vh;
  }
}
