.book_filters {
  position: absolute;
  z-index: 10;
  top: 75px;
  right: 30px;
  background: $white;
  width: 325px;
  padding: 16px;
  box-shadow: 0px 0px 8px 0px grey;
  border-radius: 10px;

  @media (max-width: 480px) {
    top: 45px;
    right: 16px;
  }

  .filter_name {
    color: $black;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .apply_button {
    text-align: end;
    margin-top: 15px;
  }

  .MuiChip-root {
    background-color: #ececec !important;
    color: $black;
    text-transform: capitalize;

    svg {
      path {
        fill: $black;
      }
    }
  }
}

.active_filters_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
