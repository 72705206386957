.details_section {
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .details_content {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding: 30px 50px;
    transition: box-shadow 0.3s ease-out 0ms;

    @media (max-width: 480px) {
      padding: 16px;
    }

    .details_component {
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media (max-width: 480px) {
        gap: 16px;
      }

      .image_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .row_title {
          font-weight: 600;
          margin: 0;
          line-height: 22px;
        }

        .cover_image {
          width: 100%;
          max-width: 300px;
        }
      }

      .details_row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;

        @media (max-width: 480px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 16px;
        }

        .row_element {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .pdf_download {
            width: fit-content;
            background-color: transparent;
            border: none;
            color: #3b3e66;
            text-decoration: underline;
            padding: 0;

            &:focus {
              outline: none;
            }
          }

          .pdf_image {
            max-width: 116px;
            max-height: 100px;
          }

          .row_title {
            font-weight: 600;
            margin: 0;
            line-height: 22px;
          }

          .row_description {
            font-size: 14px;

            @media (max-width: 480px) {
              margin: 0;
            }
          }

          .capitalize {
            text-transform: capitalize;
          }

          .content_section {
            margin: 0 0 16px;
            list-style-type: none;
            padding-left: 0;

            .row_content {
              font-size: 14px;
            }
          }

          .audio {
            @media (max-width: 480px) {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
