.upload_container {
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #e8e8e8;
  position: relative;
  margin-top: 15px;

  .delete_btn {
    display: none;
    position: absolute;
    top: 5px;
    z-index: 100;
    right: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    margin: 0;
    padding: 0;
    font-size: 0;
    border: none;

    @include maxIpad {
      display: block;
    }
  }

  &:hover {
    .delete_btn {
      display: block;
    }
  }

  .drop_text {
    width: fit-content;
    margin: 0;
    font-size: 20px;
  }

  .small_size {
    font-size: 16px;
  }

  .select_file_btn {
    color: white;
  }

  .message_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .upload_message {
      margin: 0;
      font-size: 12px;
      color: grey;
    }

    .pdf_imag {
      max-width: 116px;
      max-height: 100px;
    }

    .audio {
      margin-bottom: 5px;
    }

    .pdf_download {
      width: fit-content;
      background-color: transparent;
      border: none;
      color: #3b3e66;
      text-decoration: underline;
      padding: 0;
      margin-bottom: 5px;

      &:focus {
        outline: none;
      }
    }
  }
}

.error_container {
  border: 1px solid red;
}
